import { createI18n } from "vue-i18n"
import messages from '@intlify/unplugin-vue-i18n/messages'

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  formatFallbackMessages: true,
  numberFormats: {
    'en': {
      currency: {
        style: 'currency', currency: 'MYR', currencyDisplay: 'symbol'
      },
      decimal: {
        style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
      },
      percent: {
        style: 'percent', useGrouping: true
      }
    }
  },
  messages,
})

const t = i18n.global.t
const n = i18n.global.n

export {
  i18n,
  t,
  n
}
